import React from "react";
import "./SwingImage.css";
import lgBanner from "../../Images/Milk face wash offer web 1800-800 new.png";
import mbBanner from "../../Images/Milk face wash offer web 300-280 new.png";
import { Link } from "react-router-dom";

const SwingImage = () => {
  const isDesktop = window.innerWidth > 992;

  const banner = isDesktop ? lgBanner : mbBanner;

  return (
    <div className="swing-container">
      {/* <div className="hook"></div>
      <div className="ribbon-left"></div>
      <div className="ribbon-right"></div> */}
      <Link className="m-4 lg:m-0" to="/product/Milk-Brightening-Face-Wash-Earth-Beauty-and-You-100ml">
        <img className="swing-image" src={banner} alt="" />
      </Link>
    </div>
  );
};

export default SwingImage;
